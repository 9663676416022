import React from "react";
import styled from "styled-components";

const StyledPortada = styled.div`
  position: relative;
  .bottom-left {
    position: absolute;
    bottom: -1px;
    left: 20px;
    font-family: "lato_black";
    font-size: 30px;
    color: var(--white);
    font-weight: 900;
    letter-spacing: 2px;
  }
  img {
    width: 100%;
  }
  .desktop {
    display: none;
  }
  @media (min-width: 600px) {
    .bottom-left {
      font-size: 80px;
      bottom: -10px;
    }
  }
  @media (min-width: 992px) {
    .bottom-left {
      font-size: 70px;
      bottom: -15px;
    }
  }
  @media (min-width: 1200px) {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .bottom-left {
      font-size: 80px;
      bottom: -20px;
    }
  }
`;
export default function Portada(props) {
  const { imageMobile, imageDesktop, title } = props;
  return (
    <StyledPortada>
      <img className="mobile" src={imageMobile} alt="portada_image" />
      <img className="desktop" src={imageDesktop} alt="portada_image" />
      <div className="bottom-left">{title}</div>
    </StyledPortada>
  );
}
