import React from "react";
import styled from "styled-components";

const StyledBox = styled.div`
  background-color: var(--white);
  .container_box {
    background-color: var(--gray-ligth);
    background-color: ${(props) => props.secondary ? "var(--demon-blue)" : null};
    background-color: ${(props) => props.third ? "var(--sweet-blue)" : null};
    background-color: ${(props) => props.fourth ? "var(--purple)" : null};
    color: var(--white);
    margin: 16px;
    padding: 16px;
    border-radius: 4px;
    height: 100%;
    max-width: 2000px;
  }
  @media (min-width: 1200px) {
    .container_box {
      margin: 0 70px 100px;
      border-radius: 12px;
    }
  }
`;
export default function BoxContainer(props) {
  const { children, ...rest } = props;
  return (
    <StyledBox {...rest}>
      <div className="container_box">{children}</div>
    </StyledBox>
  );
}
