import React from "react";
import styled from "styled-components";
import Back from "../Assets/back_valores.webp";
import BoxContainer from "../utils/BoxContainer";
const StyledValores = styled.div`
  margin-top: 100px;
  background-color: var(--white);
  position: relative;
  height: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
  }
  .top-center {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    min-width: 300px;
    h1 {
      font-family: "lato_bold";
      font-size: 24px;
      color: var(--strong-blue);
      text-align: center;
    }
    p {
      font-family: "lato_bold";
      font-size: 20px;
      color: var(--strong-blue);
      font-weight: 600;
      margin-top: 50px;
      margin-bottom: 20px;
    }

    span {
      font-family: "lato_regular";
      font-size: 20px;
      color: var(--strong-blue);
      font-weight: 500;
    }
    .container_cards {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  .container_box {
    margin: 0px;
  }
  @media (min-width: 1200px) {
    /* margin-top: 200px; */
    height: 400px;
    h1 {
      font-size: 35px !important;
    }
    .test {
      margin-bottom: 100px;
      height: 100%;
    }
    .top-center {
      top: 0%;
      .container_cards {
        display: flex;
        flex-direction: row;
        margin-top: -50px;
      }
    }
    .container_box {
      width: 500px;
      padding: 40px 90px;
      display: flex;
      flex-direction: column;
    }
  }
`;
export default function ContactEmergency() {
  return (
    <StyledValores>
      <img className="test" src={Back} alt="image_back" />
      <div className="top-center">
        <div className="container_cards">
          <BoxContainer>
            <h1>Contacto urgencias</h1>
            <p>Teléfono</p>
            <a href="tel:52(55)56299800">
              <span>(55) 5629-9800</span>
            </a>
            <p>Clave</p>
            <span>9944961</span>
          </BoxContainer>
        </div>
      </div>
    </StyledValores>
  );
}
