import React, { useState, useEffect } from "react";
import Layout from "../Components/Layout/index";
import Mobile from "../Assets/portada_contacto.webp";
import Desktop from "../Assets/portada_contacto_desktop.webp";
import Portada from "../utils/Portada";
import Map from "../Components/Map";
import ContactEmergency from "../Components/ContactEmergency";
const Contacto = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);
  return hasMounted ? (
    <Layout>
      <Portada imageDesktop={Desktop} imageMobile={Mobile} title="Contacto" />
      <Map />
      <ContactEmergency />
    </Layout>
  ) : null;
}

export default Contacto 